const formatTimestamp = utcTime => {
  const time = new Date(utcTime);
  const indianTime = new Date(
    time.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
  );
  const timeDiff = Date.now() - indianTime.getTime();
  const minutesDiff = Math.floor(timeDiff / 60000);
  const hoursDiff = Math.floor(timeDiff / 3600000);
  const daysDiff = Math.floor(timeDiff / 86400000);
  const date = indianTime.getDate();
  const month = indianTime.toLocaleString('default', { month: 'short' });
  const year = indianTime.getFullYear();
  const dateStr = `${month} ${date}, ${year}`;
  const dateDiff = `${month} ${date}`;
  const secondsDiff = Math.floor(timeDiff / 1000);

  let timeAgo;

  switch (true) {
    case daysDiff >= 365:
      timeAgo = dateStr;
      break;
    case daysDiff >= 1:
      timeAgo = dateDiff;
      break;
    case hoursDiff >= 1:
      timeAgo = `${hoursDiff} h`;
      break;
    case minutesDiff >= 1:
      timeAgo = `${minutesDiff} m`;
      break;
    case secondsDiff >= 5:
      timeAgo = `${secondsDiff} s`;
      break;
    default:
      timeAgo = 'just now';
      break;
  }

  return timeAgo;
};

export default formatTimestamp;
