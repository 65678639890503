import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'microsoftConfig';
import HubManagerService from 'core/service/HubManagerService';
import { toast } from 'react-toastify';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import MicrosoftLogo from 'assets/img/microsoft-logo.png';
import DataTransferService from 'core/service/dataTransferService';

const msalInstance = new PublicClientApplication(msalConfig);

const Login = () => {
  const hubManagerService = new HubManagerService();
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  const dataTransferService = new DataTransferService();

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        setIsMsalInitialized(true);
      } catch (error) {
        handleErrorResponse('MSAL initialization failed', error.response);
      }
    };
    initializeMsal();
  }, []);

  const login = email => {
    hubManagerService
      .login({ email: email })
      .then(response => {
        if (response.data.responseCode === 200) {
          dataTransferService.setHubManagerToken(response.data.token);
          window.location.href = '/';
          toast.success(`Successfully registered as ${email}`, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        toast.error(
          `${'login'} ${error.response.data.responseCode} ${
            error.response.data.responseMessage
          }`
        );
      });
  };

  const handleMicrosoftLogin = async () => {
    if (!isMsalInitialized) {
      toast.error('MSAL initialization yet');
      return;
    }

    try {
      const response = await msalInstance.loginPopup();
      login(response.account.username);
    } catch (error) {
      if (error.errorCode === 'user_cancelled') {
        toast.error('Login canceled by user.');
      } else {
        handleErrorResponse('Microsoft login failed', error.response || error);
      }
    }
  };

  return (
    <AuthCardLayout>
      <Button
        variant="primary"
        className="d-flex align-items-center justify-content-center"
        onClick={handleMicrosoftLogin}
        disabled={!isMsalInitialized}
      >
        <img className="px-2" src={MicrosoftLogo} alt="microsoft-logo" />
        Continue with Microsoft
      </Button>
    </AuthCardLayout>
  );
};

export default Login;
