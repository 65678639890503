import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Dropdown } from 'react-bootstrap';
import HubManagerService from 'core/service/HubManagerService';
import React, { useMemo, useState, useEffect } from 'react';
import CardDropdown from 'components/common/CardDropdown';
import SubtleBadge, {
  chargeByColor,
  statusByColor
} from 'components/common/SubtleBadge';
import { useNavigate } from 'react-router-dom';
import './../../../../assets/scss/user.scss';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import './HubVehiclesComponent.scss';
import emptyImage from 'assets/img/No-vehicles-found.png';
import {
  PaginationComponent,
  toPascalWord,
  DropdownComponent
} from 'components/commonComponent/commonComponents';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';

const HubVehiclesComponent = () => {
  const ENTRIES_PER_PAGE = 10;
  const [data, setData] = useState(null);
  const [status, setStatus] = useState('All');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const hubManagerService = new HubManagerService();
  const [sort, setSort] = useState('Charging High to low');
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const columnNames = ['Vehicle Number', 'Charge', 'Status', 'Action'];

  useEffect(() => {
    const savedState = localStorage.getItem('hubVehiclesState');
    if (savedState) {
      const { currentPage, status, sort } = JSON.parse(savedState);
      setCurrentPage(currentPage);
      setStatus(status);
      setSort(sort);
    }
  }, []);

  useEffect(() => {
    const state = { currentPage, status, sort };
    localStorage.setItem('hubVehiclesState', JSON.stringify(state));
  }, [currentPage, status, sort]);

  const renderTableHeaders = () => (
    <tr>
      {columnNames.map(columnName => (
        <th key={columnName} className="col-fixed-width">
          {columnName}
        </th>
      ))}
    </tr>
  );

  const filteredEntries = useMemo(() => {
    if (!data || !Array.isArray(data.cabDetailsDTOList)) {
      return [];
    }

    const statusFilter = (entry, status) => {
      const cabStatus = entry.cabStatus.toUpperCase();
      return !!(
        (status === 'Available' && cabStatus === 'AVAILABLE') ||
        (status === 'Not Available' && cabStatus === 'NOT_AVAILABLE') ||
        (status === 'Charging' && cabStatus === 'CHARGING') ||
        status === 'All'
      );
    };

    const filteredData = data.cabDetailsDTOList.filter(entry =>
      statusFilter(entry, status)
    );

    setIsEmpty(filteredData.length === 0);
    return filteredData;
  }, [data, status]);

  const TableRow = ({ data }) => {
    const navigate = useNavigate();
    if (!data || typeof data !== 'object') {
      console.error('Invalid data:', data);
      return null;
    }

    const { cabNumber, cabCharge, cabStatus } = data;
    if (!cabNumber || typeof cabNumber !== 'string') {
      console.error('Invalid vehicleNumber:', cabNumber);
      return null;
    }

    const handleEditClick = event => {
      event.preventDefault();
      navigate(
        `/manage-vehicles/hub-vehicles/edit?cabNumber=${encodeURIComponent(
          cabNumber
        )}&cabCharge=${encodeURIComponent(
          cabCharge || ''
        )}&cabStatus=${encodeURIComponent(cabStatus || '')}`
      );
    };

    return (
      <tr className="align-middle">
        <td className="blue-text">
          <p href="#">{cabNumber}</p>
        </td>
        <td>
          <SubtleBadge pill bg={chargeByColor(cabCharge)}>
            {cabCharge + '%'}
          </SubtleBadge>
        </td>
        <td className={`col-fixed-width ${statusByColor(cabStatus)}`}>
          {toPascalWord(cabStatus)}
        </td>
        <td>
          <CardDropdown drop="end">
            <Dropdown.Item href="#!" onClick={e => handleEditClick(e)}>
              Edit
            </Dropdown.Item>
          </CardDropdown>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sort, status, searchTerm]);

  const fetchData = () => {
    const startIndex = ((currentPage - 1) / ENTRIES_PER_PAGE) * 10;
    let statusParam;

    if (status === 'All') {
      statusParam = 'all';
    } else {
      statusParam = status.toUpperCase().replace(' ', '_');
    }

    setLoading(true);
    setError(null);

    let response;
    if (searchTerm.trim()) {
      response = hubManagerService.searchHubCabs(
        startIndex,
        ENTRIES_PER_PAGE,
        statusParam === 'all'
          ? 'AVAILABLE,NOT_AVAILABLE,CHARGING'
          : statusParam,
        sort,
        searchTerm.trim()
      );
    } else {
      response = hubManagerService.fetchHubCabs({
        pageIndex: startIndex,
        pageSize: ENTRIES_PER_PAGE,
        status: statusParam,
        sort: sort
      });
    }

    response
      .then(res => {
        if (res.data && res.data.responseCode === 200) {
          setData(res.data.responseBody);
          setIsEmpty(res.data.responseBody.cabDetailsDTOList.length === 0);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
        setData(null);
        setIsEmpty(true);
        handleErrorResponse('fetchVehicles', err.response);
      });
  };

  const renderCabs = () =>
    filteredEntries.map(entry => (
      <TableRow data={entry} key={entry.cabNumber} />
    ));

  const handleStatusChange = selectedStatus => {
    setStatus(selectedStatus === 'All' ? 'All' : selectedStatus);
    setCurrentPage(1);
  };

  const handleSortChange = selectedSort => {
    setSort(
      selectedSort === 'Charging High to low'
        ? 'Charging High to low'
        : selectedSort
    );
    setCurrentPage(1);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(data?.totalItems / ENTRIES_PER_PAGE) || 1;
  const isNextDisabled = currentPage >= totalPages;
  const isPrevDisabled = currentPage === 1;

  const nextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (!isPrevDisabled) {
      setCurrentPage(currentPage - 1);
    }
  };

  const vehicleStatusOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'Available', label: 'Available' },
    { id: 3, value: 'Not Available', label: 'Not Available' },
    { id: 4, value: 'Charging', label: 'Charging' }
  ];

  const sortOptions = [
    { id: 1, value: 'Charging High to low', label: 'Charging High to low' },
    { id: 2, value: 'Charging Low to high', label: 'Charging Low to high' }
  ];

  return (
    <div className="py-3">
      <Header breadcrumb="Manage Vehicles/Hub vehicle" title="Hub Vehicles" />

      <Card>
        <div className="bottom-padding">
          <Flex className="justify-content-between flex-wrap">
            <div className="search-container justify-content-start">
              <FontAwesomeIcon icon="search" className="search-icon" />
              <input
                className="search-content"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <Flex className="justify-content-end">
              <div className="me-3">
                <DropdownComponent
                  title="Sort"
                  selected={sort}
                  options={sortOptions}
                  onSelect={handleSortChange}
                  customStyle={true}
                />
              </div>
              <DropdownComponent
                title="Status"
                selected={status}
                options={vehicleStatusOptions}
                onSelect={handleStatusChange}
              />
            </Flex>
          </Flex>
        </div>
      </Card>

      {loading ? (
        <div className="text-center">
          <Loader loading={loading} />
        </div>
      ) : error ? (
        <div className="text-center">
          <img src={emptyImage} alt="Error" className="empty-image" />
          <p className="mt-3 no-content-text">No Vehicles found</p>
        </div>
      ) : isEmpty ? (
        <div className="text-center">
          <img src={emptyImage} alt="No vehicles" className="empty-image" />
          <p className="mt-3 no-content-text">No Vehicles found</p>
        </div>
      ) : (
        <Card>
          <Table striped responsive hover>
            <thead className="header-padding">{renderTableHeaders()}</thead>
            <tbody>{renderCabs()}</tbody>
          </Table>
          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={isNextDisabled}
            isPrevDisabled={isPrevDisabled}
          />
        </Card>
      )}
    </div>
  );
};

export default HubVehiclesComponent;
