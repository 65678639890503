import imageCompression from 'browser-image-compression';

const compressImage = async file => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  return imageCompression(file, options);
};
export default compressImage;
