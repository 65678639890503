import React from 'react';
import StatisticsCard from './StatisticsCard';
import { Col, Row } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-1.png';
const StatisticsCards = ({ ridesValue, ratingValue }) => {
  const statsData = [
    {
      title: 'Rides',
      value: ridesValue,
      valueClassName: 'text-info',
      image: bg1
    },
    {
      title: 'Rating',
      value: ratingValue,
      decimal: true,
      valueClassName: 'text-warning',
      image: bg2
    }
  ];

  return (
    <Row className="g-3">
      {statsData.map(stat => {
        return (
          <Col key={stat.title} lg={6} sm={6} md={6}>
            <StatisticsCard
              className="d-flex align-items-center justify-content-center"
              stat={stat}
              style={{ minWidth: '12rem' }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default StatisticsCards;
