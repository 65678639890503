import imageMember2 from 'assets/img/team/2.jpg';
import imageMember3 from 'assets/img/team/3.jpg';

const autoCompleteInitialItem = [
  {
    id: 1,
    url: '/events/event-detail',
    breadCrumbTexts: ['Pages ', ' Events'],
    catagories: 'recentlyBrowsedItems'
  },
  {
    id: 2,
    url: '/e-commerce/customers',
    breadCrumbTexts: ['E-commerce ', ' Customers'],
    catagories: 'recentlyBrowsedItems'
  },
  {
    id: 3,
    catagories: 'suggestedFilters',
    url: '/e-commerce/customers',
    key: 'customers',
    text: 'All customers list',
    type: 'warning'
  },
  {
    id: 4,
    catagories: 'suggestedFilters',
    url: '/events/event-detail',
    key: 'events',
    text: 'Latest events in current month',
    type: 'success'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/e-commerce/product/product-grid',
    key: 'products',
    text: 'Most popular products',
    type: 'info'
  },
  {
    id: 8,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: imageMember2,
      size: 'l',
      status: 'status-online'
    },
    title: 'Anna Karinina',
    text: 'Technext Limited'
  },
  {
    id: 10,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: imageMember3,
      size: 'l'
    },
    title: 'Emma Watson',
    text: 'Google'
  }
];

export default autoCompleteInitialItem;
