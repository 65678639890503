import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

const AuthCardLayout = ({ children }) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <Card className="overflow-hidden z-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-white text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <div
                      className="z-1 position-relative"
                      data-bs-theme="light"
                    >
                      <Link
                        className="link-light font-sans-serif fw-bolder fs-5 d-inline-block"
                        to="/"
                      >
                        Rush
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthCardLayout;
