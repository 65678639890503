import { faCar } from '@fortawesome/free-solid-svg-icons';
export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
      exact: true
    },
    {
      name: 'Manage Drivers',
      active: true,
      icon: 'user',
      children: [
        {
          name: 'Add Driver',
          to: '/manage-drivers/addDriver',
          active: true
        },
        {
          name: 'Drivers',
          to: '/manage-drivers/drivers',
          active: true
        },
        {
          name: 'Active Drivers',
          to: '/manage-drivers/active-drivers',
          active: true
        }
      ]
    },
    {
      name: 'Manage Vehicles',
      active: true,
      icon: faCar,
      children: [
        {
          name: 'Add Vehicle',
          to: '/manage-vehicles/addVehicle',
          active: true
        },
        {
          name: 'Hub Vehicles',
          to: '/manage-vehicles/hub-vehicles',
          active: true
        },
        {
          name: 'Online Vehicle',
          to: '/manage-vehicles/online-vehicles',
          active: true
        },
        {
          name: 'Change Vehicle',
          to: '/manage-vehicles/changeVehicle',
          active: true
        }
      ]
    },
    {
      name: 'Manage Rides',
      active: true,
      icon: 'clock',
      to: '/manage-rides'
    },
    {
      name: 'Maps',
      active: true,
      icon: 'map',
      to: '/map',
      exact: true
    }
  ]
};

export default [dashboardRoutes];
