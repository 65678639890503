export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },

  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};
