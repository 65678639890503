import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import StatisticsCards from 'components/dashboards/sass/stats-cards/StatisticsCards';
import DataTransferService from 'core/service/dataTransferService';
import HubManagerService from 'core/service/HubManagerService';
import AadhaarLogo from 'assets/img/team/adhaar_logo.svg';
import GovtLogo from 'assets/img/team/ap-govt-logo.svg';
import ImagePreviewComponent from 'components/ImagePreview/ImagePreviewComponent';
import BlobStorageService from 'core/service/StorageService';

const DriverDetails = () => {
  const location = useLocation();
  const hubManagerService = new HubManagerService();
  const dataTransferService = new DataTransferService();
  const queryParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState({ duid: '' });
  const [dynamicRidesValue, setDynamicRidesValue] = useState(0);
  const [dynamicRatingValue, setDynamicRatingValue] = useState(0);
  const setLoading = useState(false)[1];
  const [getDriverDetails, setGetDriverDetails] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const blobStorageService = new BlobStorageService();

  useEffect(() => {
    setFormData({ duid: queryParams.get('duid') || '' });
  }, [location.search]);

  useEffect(() => {
    if (formData.duid) {
      fetchDriverDetails();
    }
  }, [formData.duid]);

  const fetchDriverDetails = async () => {
    try {
      setLoading(true);
      const response = await hubManagerService.fetchDriverDetails(
        formData.duid
      );
      if (response.data.responseCode === 200) {
        setLoading(false);
        setGetDriverDetails(response.data.responseBody);
        setDynamicRidesValue(response.data.responseBody.rides);
        setDynamicRatingValue(response.data.responseBody.driverDetails.rating);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleImageClick = (images, index) => {
    setPreviewImages(images);
    setCurrentIndex(index);
    setShowPreview(true);
  };

  const handleClose = () => {
    setShowPreview(false);
    setPreviewImages([]);
    setCurrentIndex(0);
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : previewImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < previewImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className={`driver-details ${showPreview ? 'blur-background' : ''}`}>
      <Card className="ps-3 py-3">
        <p className="text-sky-blue-fw-500-fs-13 mb-0">Driver</p>
        {getDriverDetails && (
          <p className="text-fw-500-fs-23 mb-0">
            {getDriverDetails.driverDetails.firstName}
          </p>
        )}
      </Card>

      <div className="py-3">
        <StatisticsCards
          ridesValue={dynamicRidesValue}
          ratingValue={dynamicRatingValue}
        />
      </div>

      <Card className="driverDetails">
        <p className="text-fw-400-fs-19 mb-0 p-3">Details</p>
        <Card.Body className="position-relative bg-color-gray">
          <div className="ps-3">
            <Row>
              <Col className="px-0 " lg={2}>
                {getDriverDetails &&
                  getDriverDetails.driverDetails.profileImage && (
                    <img
                      src={
                        dataTransferService.getBloburl() +
                        getDriverDetails.driverDetails.profileImage
                      }
                      className="img-fluid"
                      alt="driver-profile"
                    />
                  )}
              </Col>
              <Col className="px-3" lg={10}>
                <Row>
                  <Col lg={12}>
                    <p className="mb-0 text-fw-500-fs-13">
                      ACCOUNT INFORMATION
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="pt-2">
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">ID</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.driverDetails?.duid}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Created</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {blobStorageService.formatDate(
                              getDriverDetails?.createdTime
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Email</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.driverDetails?.email}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Gender</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.driverDetails?.gender}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Phone number</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.driverDetails?.mobileNumber}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="pt-2">
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Hub location</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.hubLocation}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">Aadhaar</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.aadhaarCardNumber}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">DL number</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0 text-fw-400-fs-16">
                            {getDriverDetails?.drivingLicenseNumber}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      <div className="pt-3">
        <Row>
          <Col>
            <Card className="p-3">
              <div className="d-flex align-items-center justify-content-between">
                <p>Adhaar Card</p>
                <img src={AadhaarLogo} className="ht-90" alt="aadhar-logo" />
              </div>
              {getDriverDetails && (
                <img
                  className="ht-277 pt-3"
                  src={
                    dataTransferService.getBloburl() +
                    getDriverDetails.aadhaarImages[0]
                  }
                  alt="Aadhar"
                  onClick={() =>
                    handleImageClick(
                      getDriverDetails.aadhaarImages.map(
                        img => dataTransferService.getBloburl() + img
                      ),
                      0
                    )
                  }
                />
              )}
            </Card>
          </Col>
          <Col>
            <Card className="p-3">
              <div className="d-flex align-items-center justify-content-between">
                <p>Driving License</p>
                <img src={GovtLogo} className="ht-90" alt="govtLogo" />
              </div>
              {getDriverDetails && (
                <img
                  className="ht-277 pt-3"
                  src={
                    dataTransferService.getBloburl() +
                    getDriverDetails.licenseImages[0]
                  }
                  alt="driving-license"
                  onClick={() =>
                    handleImageClick(
                      getDriverDetails.licenseImages.map(
                        img => dataTransferService.getBloburl() + img
                      ),
                      0
                    )
                  }
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <ImagePreviewComponent
        show={showPreview}
        images={previewImages}
        currentIndex={currentIndex}
        handleClose={handleClose}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </div>
  );
};

export default DriverDetails;
