export default class DateService {
  constructor() {
    this.dateKey = 'selectedDate';
  }

  getPersistedDate(key, defaultValue = new Date()) {
    const storedDate = localStorage.getItem(key);
    if (storedDate) {
      return new Date(storedDate);
    }
    return defaultValue;
  }

  setPersistedDate(key, date) {
    localStorage.setItem(key, date.toISOString());
  }

  clearPersistedFlag(flag) {
    localStorage.removeItem(flag);
  }

  isFlagSet(flag) {
    return localStorage.getItem(flag) === 'true';
  }

  getSelectedDate() {
    return this.getPersistedDate(this.dateKey);
  }

  setSelectedDate(date) {
    this.setPersistedDate(this.dateKey, date);
  }
}
