import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NotificationService from 'core/service/NotificationService';
import WebSocketService from 'core/service/WebSocketService';

const NotificationDropdown = () => {
  const notificationService = new NotificationService();
  const [notificationCount, setNotificationCount] = useState(0);
  const [hasNotification, setHasNotification] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleNotificationReceived = flag => {
      setHasNotification(flag);
      getNotificationCount();
    };
    const webSocket = new WebSocketService({
      onNotificationReceived: handleNotificationReceived
    });
    webSocket.connect();

    return () => {
      webSocket.disconnect();
    };
  }, []);

  const getNotificationCount = () => {
    notificationService
      .fetchNotificationsCount()
      .then(response => {
        if (response.data.responseCode === 200) {
          setNotificationCount(response.data.responseBody);
        } else {
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        toast.error(`${error.response.data.responseMessage}`);
      });
  };
  const navigateToNotification = event => {
    event.preventDefault();
    notificationService
      .markAsAllRead()
      .then(response => {
        if (response.data.responseCode === 200) {
          setNotificationCount(0);
          setHasNotification(false);
          navigate('/notification');
        } else {
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        toast.error(`${error.response.data.responseMessage}`);
      });
  };
  return (
    <div
      className={` ${
        hasNotification
          ? 'notification-indicator notification-indicator-warning position-relative notification-indicator-fill'
          : ''
      } px-0`}
      onClick={navigateToNotification}
    >
      {notificationCount > 0 && (
        <span className="notification-indicator-number">
          {notificationCount}
        </span>
      )}
      <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
    </div>
  );
};

export default NotificationDropdown;
