import './loader.scss';
import React from 'react';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the loader is above other content */
`;

const Loader = ({ loading }) => {
  return (
    loading && (
      <div className="loader-overlay">
        <ClipLoader
          color={'#2C7BE5'}
          loading={loading}
          css={override}
          size={50}
        />
      </div>
    )
  );
};

export default Loader;
