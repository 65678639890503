import HubManagerService from 'core/service/HubManagerService';
import StorageService from 'core/service/StorageService';
import { Button, Form, Card } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Loader from 'core/loader/loader';
import compressImage from './../../../../core/imageCompression/imageCompression';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';
import DropzoneArea from 'components/common/DropzoneComponent';
import UploadedImages from 'components/common/UploadedImagesComponent';

const AddVehicleComponent = () => {
  const [loading, setLoading] = useState(false);
  const hubManagerService = new HubManagerService();
  const storageService = new StorageService();
  const [formData, setFormData] = useState({
    cabNumber: '',
    cabType: 'Sedan',
    cabColor: '',
    cabPhotos: [],
    rcNumber: '',
    chassisNumber: '',
    engineNumber: '',
    rcPhotos: [],
    insurancePhotos: []
  });

  const schema = yup
    .object({
      cabPhotos: yup
        .array()
        .max(2, 'You can only upload up to 2 cabPhotos.')
        .required('Vehicle photos is required.'),
      insurancePhotos: yup
        .array()
        .max(2, 'You can only upload up to 2 InsuranceDocuments.')
        .required('Insurance documents is required.'),
      rcPhotos: yup
        .array()
        .max(2, 'You can only upload up to 2 RCPhotos.')
        .required('RC photos is required.'),
      cabType: yup.string().required('Vehicle type is required'),
      cabColor: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Vehicle color is required'),
      rcNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Rc number is required'),
      cabNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Vehicle number is required'),
      chassisNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Chassis number is required'),
      engineNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Engine number is required')
    })
    .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { cabType: 'Sedan' }
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    clearErrors(name);
    setValue(name, value);
  };

  const handleCabTypeChange = e => {
    const { name, value } = e.target;
    setValue(name, value);
    setFormData({
      ...formData,
      cabType: value
    });
  };

  const [cabFiles, setCabFiles] = useState([]);
  const [insuranceFiles, setInsuranceFiles] = useState([]);
  const [rcFiles, setRcFiles] = useState([]);

  const onSubmit = async formData => {
    setLoading(true);
    schema.validate(formData, { abortEarly: false });
    const allPhotos = [
      ...formData.cabPhotos,
      ...formData.insurancePhotos,
      ...formData.rcPhotos
    ];
    const compressedPhotos = await Promise.all(
      allPhotos.map(async file => {
        if (file.size > storageService.MAX_UPLOAD_SIZE_IN_BYTES) {
          const compressedFile = await compressImage(file);
          return compressedFile;
        }
        return file;
      })
    );
    storageService
      .uploadImage(compressedPhotos)
      .then(response => {
        if (response.data.responseCode === 200) {
          const cabPhotosCount = formData.cabPhotos.length;
          const insurancePhotosCount = formData.insurancePhotos.length;
          const rcPhotosCount = formData.rcPhotos.length;
          const cabPhotosIndex = Array.from(
            { length: cabPhotosCount },
            (_, i) => i
          );
          const insurancePhotosIndex = Array.from(
            { length: insurancePhotosCount },
            (_, i) => i + cabPhotosCount
          );
          const rcPhotosIndex = Array.from(
            { length: rcPhotosCount },
            (_, i) => i + cabPhotosCount + insurancePhotosCount
          );

          const cabPhotosResponseBody = response.data.responseBody.filter(
            (_, index) => cabPhotosIndex.includes(index)
          );
          const insurancePhotosResponseBody = response.data.responseBody.filter(
            (_, index) => insurancePhotosIndex.includes(index)
          );
          const rcPhotosResponseBody = response.data.responseBody.filter(
            (_, index) => rcPhotosIndex.includes(index)
          );
          let cabType = formData.cabType;
          if (formData.cabType === 'Sedan') {
            cabType = 'SEDAN_EV';
          }
          const cabDetailsDTO = {
            cabNumber: formData.cabNumber,
            cabType: cabType,
            cabColor: formData.cabColor,
            cabPhoto: cabPhotosResponseBody,
            cabDocumentsDTO: {
              rcNumber: formData.rcNumber,
              chassisNumber: formData.chassisNumber,
              engineNumber: formData.engineNumber,
              rcDocumentImage: rcPhotosResponseBody,
              insuranceImage: insurancePhotosResponseBody
            }
          };
          saveCabDetails(cabDetailsDTO);
        } else {
          setLoading(false);
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(`${error.response.data.responseMessage}`);
      });
  };

  const saveCabDetails = data => {
    hubManagerService
      .postCabs(data)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(`${response.data.responseMessage}`);
          window.location.reload();
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('saveDriverDetails', error.response);
      });
  };

  return (
    <div className="py-3">
      <Header breadcrumb="Manage Vehicles/Add Vehicle" title="Add Vehicle" />

      <Card className="custom-card">
        <h5 className="custom-event-card p-3 mb-0">Vehicle details</h5>
        <div className="vehicle-form">
          <h6 className="text pb-2 pt-3 px-3">Upload Vehicle Photo</h6>
          <Form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="cabPhotos px-3">
              <DropzoneArea
                name="cabPhotos"
                files={cabFiles}
                setFiles={setCabFiles}
                maxFiles={2}
                accept={{
                  'image/*': ['.jpeg', '.png', '.jpg']
                }}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
              />
              <UploadedImages
                files={cabFiles}
                setFiles={setCabFiles}
                setValue={setValue}
                valueKey="cabPhotos"
              />
            </div>

            <div className="item-pair pt-3 px-3">
              <div className="row">
                <div className="col">
                  <p className="input-field pb-4">Vehicle Number</p>
                </div>
                <div className="col">
                  <p className="input-field pb-4">Vehicle color</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    className="rectangle"
                    name="cabNumber"
                    value={formData.cabNumber}
                    placeholder="AP XXXX XXXX XXXX"
                    onChange={handleChange}
                  />
                  {errors.cabNumber && (
                    <div className="error">{errors.cabNumber?.message}</div>
                  )}
                </div>
                <div className="col">
                  <input
                    className="rectangle"
                    name="cabColor"
                    value={formData.cabColor}
                    placeholder="Vehicle color"
                    onChange={handleChange}
                  />
                  {errors.cabColor && (
                    <div className="error">{errors.cabColor?.message}</div>
                  )}
                </div>
              </div>
              <div className="drop-down">
                <p className="input-field pt-2 pb-4">Vehicle Model</p>
                <select
                  className="rectangle"
                  name="cabType"
                  value={formData.cabType}
                  onChange={handleCabTypeChange}
                >
                  <option value="">Select</option>
                  <option value="Sedan">Sedan EV</option>
                </select>
                {errors.cabType && (
                  <div className="error">{errors.cabType?.message}</div>
                )}
              </div>
            </div>

            <div className="upload-documents mt-5">
              <h5 className="custom-event-card p-3 mb-0">Upload Documents</h5>
              <div className="pt-2 px-3">
                <div className="item-pair pt-3">
                  <div className="row">
                    <div className="col">
                      <p className="input-field pb-4">RC Number</p>
                    </div>
                    <div className="col">
                      <p className="input-field pb-4">Chassis Number</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        className="rectangle"
                        name="rcNumber"
                        value={formData.rcNumber}
                        placeholder="RC Number"
                        onChange={handleChange}
                      />
                      {errors.rcNumber && (
                        <div className="error">{errors.rcNumber?.message}</div>
                      )}
                    </div>
                    <div className="col">
                      <input
                        className="rectangle"
                        name="chassisNumber"
                        value={formData.chassisNumber}
                        placeholder="Chassis Number"
                        onChange={handleChange}
                      />
                      {errors.chassisNumber && (
                        <div className="error">
                          {errors.chassisNumber?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-3">
                    <p className="input-field pb-4">Engine number</p>
                    <input
                      className="rectangle"
                      name="engineNumber"
                      value={formData.engineNumber}
                      placeholder="Engine Number"
                      onChange={handleChange}
                    />
                    {errors.engineNumber && (
                      <div className="error">
                        {errors.engineNumber?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="rc-documents pt-3">
                  <h6 className="text pb-2">Upload RC Document Photos</h6>
                  <DropzoneArea
                    name="rcPhotos"
                    files={rcFiles}
                    setFiles={setRcFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                  <UploadedImages
                    files={rcFiles}
                    setFiles={setRcFiles}
                    setValue={setValue}
                    valueKey="rcPhotos"
                  />
                </div>
                <div className="insurance-photos pt-3">
                  <h6 className="text pb-2">Upload Insurance Photos</h6>
                  <DropzoneArea
                    name="insurancePhotos"
                    files={insuranceFiles}
                    setFiles={setInsuranceFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                  <UploadedImages
                    files={insuranceFiles}
                    setFiles={setInsuranceFiles}
                    setValue={setValue}
                    valueKey="insurancePhotos"
                  />
                </div>
                {loading ? (
                  <div className="mt-5">
                    <Loader loading={true} />
                  </div>
                ) : (
                  <div className="pt-5 pb-3 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary savebutton rounded-pill"
                      disabled={
                        !formData.cabColor ||
                        !formData.cabNumber ||
                        !formData.cabPhotos ||
                        !formData.cabType ||
                        !formData.rcNumber ||
                        !formData.insurancePhotos ||
                        !formData.chassisNumber ||
                        !formData.engineNumber ||
                        !formData.rcPhotos
                      }
                    >
                      Save Details
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddVehicleComponent;
