import { Appconfig } from 'core/config/app.config';
import axiosInstance from 'core/tokenInterceptor/AxiosInstance';

export class NotificationService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'notification';
  }

  fetchHubRequestNotifications(pageIndex, pageSize) {
    const params = {
      pageIndex,
      pageSize
    };
    return axiosInstance.get(this.Api + '/get-hub-requests', {
      params: params
    });
  }

  markAsAllRead() {
    return axiosInstance.put(this.Api + '/hub/mark-as-read');
  }

  fetchNotificationsCount() {
    return axiosInstance.get(this.Api + '/hub-unread');
  }
}
export default NotificationService;
