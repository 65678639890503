import React from 'react';
import { Row } from 'react-bootstrap';

const Profile = () => {
  return (
    <>
      <Row className="g-3 mb-3"></Row>
    </>
  );
};

export default Profile;
