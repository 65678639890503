export default class DataTransferService {
  getBloburl() {
    return 'https://rushdev.blob.core.windows.net/images/';
  }

  setHuid(token) {
    return localStorage.setItem('hubuid', token);
  }

  setHubManagerToken(HubManangerToken) {
    return localStorage.setItem('hub-manager-token', HubManangerToken);
  }

  getHubManagerToken() {
    return localStorage.getItem('hub-manager-token');
  }

  setdefualtProfileImage() {
    const defaultProfileImageString =
      '7305fece-bb9f-43d0-a961-35212e1a9c7fno-profile.png';
    return this.getBloburl() + defaultProfileImageString;
  }

  logout() {
    return localStorage.clear();
  }

  getHubUid() {
    return localStorage.getItem('hubuid');
  }
}
