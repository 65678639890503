import React from 'react';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import { getSize } from 'helpers/utils';
const UploadedImages = ({ files, setFiles, setValue, valueKey, disabled }) => {
  const handleRemove = path => {
    if (disabled) return;

    const filteredFiles = files.filter(file => file.path !== path);
    setFiles(filteredFiles);
    setValue(valueKey, filteredFiles.length ? filteredFiles : null);
  };

  return (
    <div>
      {files.map((file, index) => (
        <Flex
          alignItems="center"
          className="py-3 border-bottom btn-reveal-trigger"
          key={file.path || index}
        >
          <img
            className="rounded"
            width={40}
            height={40}
            src={file.preview}
            alt={file.path}
          />
          <Flex
            justifyContent="between"
            alignItems="center"
            className="ms-3 flex-1"
          >
            <div>
              <h6>{file.path}</h6>
              <Flex className="position-relative" alignItems="center">
                <p className="fs-10 text-400 line-height-1">
                  <strong>{disabled ? '' : getSize(file.size)}</strong>
                </p>
              </Flex>
            </div>
          </Flex>
          {!disabled && (
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove(file.path)}
                >
                  Remove
                </Dropdown.Item>
              </div>
            </CardDropdown>
          )}
        </Flex>
      ))}
    </div>
  );
};

export default UploadedImages;
