import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubtleBadge = ({ bg = 'primary', pill, children, className }) => {
  return (
    <div
      className={classNames(className, `badge badge-subtle-${bg}`, {
        'rounded-pill': pill
      })}
    >
      {children}
    </div>
  );
};

SubtleBadge.propTypes = {
  bg: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark'
  ]),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SubtleBadge;

export const chargeByColor = cabCharge => {
  if (typeof cabCharge === 'number' && !isNaN(cabCharge)) {
    switch (true) {
      case cabCharge >= 90 && cabCharge <= 100:
        return 'success';
      case cabCharge >= 50 && cabCharge < 90:
        return 'warning';
      case cabCharge >= 0 && cabCharge < 50:
        return 'danger';
      default:
        return 'primary';
    }
  }
  return 'primary';
};

export const statusByColor = status => {
  if (typeof status === 'string') {
    switch (status.toUpperCase()) {
      case 'COMPLETED':
        return 'success';
      case 'ASSIGNED':
        return 'primary';
      case 'CANCELLED':
        return 'danger';
      case 'ONBOARD':
        return 'primary';
      case 'ARRIVED':
        return 'primary';
      case 'AVAILABLE':
        return 'green-text';
      case 'NOT_AVAILABLE':
        return 'red-text';
      case 'CHARGING':
        return 'pacific-blue-text';
      default:
        return 'primary';
    }
  }
  return 'primary';
};
