import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customCalender = forwardRef(({ value, onClick }, ref) => {
  return (
    <div className="position-relative">
      <Button ref={ref} onClick={onClick} variant="falcon-default">
        {value}
        <FontAwesomeIcon icon="calendar-alt" className="icon-color ms-2" />
      </Button>
    </div>
  );
});

export default customCalender;
