import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { goBack } from 'components/commonComponent/commonComponents';

const Header = ({ breadcrumb, title }) => {
  return (
    <div className="ps-3 pb-3">
      <p className="color-cyan-blue-fs-12">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={goBack}
          className="pointer"
        />
        <span className="ps-3">{breadcrumb}</span>
      </p>
      <h5 className="color-cyan-blue-fs-20 pt-3 mb-0">{title}</h5>
    </div>
  );
};

export default Header;
