import StarRating from 'components/common/StarRating';
import Background from 'components/common/Background';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CountUp from 'react-countup';
import React from 'react';

const StatisticsCard = ({ stat, ...rest }) => {
  const { title, value, decimal, valueClassName, image, className } = stat;

  return (
    <Card className={classNames(className, 'overflow-hidden')} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>{title}</h6>
        <div className="d-flex  justify-content-center">
          <div
            className={classNames(
              valueClassName,
              'display-4 fs-5 mb-2 fw-normal font-sans-serif me-3'
            )}
          >
            <CountUp
              start={0}
              end={value}
              duration={2}
              decimals={decimal ? 1 : 0}
              decimal="."
            />
          </div>
          {title === 'Rating' && <StarRating readonly rating={value} />}
        </div>
      </Card.Body>
    </Card>
  );
};

StatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    decimal: PropTypes.bool,
    valueClassName: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    badgeText: PropTypes.string,
    badgeBg: PropTypes.string,
    image: PropTypes.string,
    className: PropTypes.string
  })
};

export default StatisticsCard;
