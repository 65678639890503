import React from 'react';
import HubManagerService from 'core/service/HubManagerService';
import StorageService from 'core/service/StorageService';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import compressImage from './../../../../core/imageCompression/imageCompression';
import Loader from 'core/loader/loader';
import UploadedImages from 'components/common/UploadedImagesComponent';
import DropzoneArea from 'components/common/DropzoneComponent';
import Header from 'components/common/Header';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';

const AddDriverComponent = () => {
  const [loading, setLoading] = useState(false);
  const hubManagerService = new HubManagerService();
  const storageService = new StorageService();
  const [profileFiles, setProfileFiles] = React.useState([]);
  const [aadharFiles, setAadharFiles] = React.useState([]);
  const [licenseFiles, setLicenseFiles] = React.useState([]);
  const [formData, setFormData] = useState({
    profileImage: [],
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    gender: '',
    aadharCardNumber: '',
    aadharPhotos: [],
    drivingLicenseNumber: '',
    licenseImage: []
  });

  const schema = yup
    .object({
      profileImage: yup
        .array()
        .max(1, 'You can only upload only one driverPhoto.')
        .required('Driver photo is required.'),
      aadharPhotos: yup
        .array()
        .max(2, 'You can only upload up to 2 AadharDocuments.')
        .required('Aadhar documents is required.'),
      licenseImage: yup
        .array()
        .max(2, 'You can only upload up to 2 licensePhotos.')
        .required('Driving License photos is required.'),
      firstName: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('First Name is required'),
      lastName: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Last Name is required'),
      mobileNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 digits'),
      email: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .email('Invalid email format')
        .required('Email is required'),
      gender: yup.string().required('Gender is required'),
      aadharCardNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Aadhar Card Number is required'),
      drivingLicenseNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .required('Driving License Number is required')
    })
    .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async formData => {
    setLoading(true);
    schema.validate(formData, { abortEarly: false });
    const allPhotos = [
      ...formData.profileImage,
      ...formData.aadharPhotos,
      ...formData.licenseImage
    ];
    const compressedPhotos = await Promise.all(
      allPhotos.map(async file => {
        if (file.size > storageService.MAX_UPLOAD_SIZE_IN_BYTES) {
          const compressedFile = await compressImage(file);
          return compressedFile;
        }
        return file;
      })
    );
    storageService
      .uploadImage(compressedPhotos)
      .then(response => {
        if (response.data.responseCode === 200) {
          const profileImageCount = formData.profileImage.length;
          const aadharPhotosCount = formData.aadharPhotos.length;
          const licenseImageCount = formData.licenseImage.length;
          const profileImageIndex = Array.from(
            { length: profileImageCount },
            (_, i) => i
          );
          const aadharPhotosIndex = Array.from(
            { length: aadharPhotosCount },
            (_, i) => i + profileImageCount
          );
          const licenseImageIndex = Array.from(
            { length: licenseImageCount },
            (_, i) => i + profileImageCount + aadharPhotosCount
          );

          const profileImageResponseBody = response.data.responseBody.filter(
            (_, index) => profileImageIndex.includes(index)
          );
          const aadharPhotosResponseBody = response.data.responseBody.filter(
            (_, index) => aadharPhotosIndex.includes(index)
          );
          const licenseImageResponseBody = response.data.responseBody.filter(
            (_, index) => licenseImageIndex.includes(index)
          );
          const driverDTO = {
            driverAccountDTO: {
              mobileNumber: formData.mobileNumber
            },
            driverDetailsDTO: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              gender: formData.gender,
              profileImage: profileImageResponseBody
            },

            driverDocumentDTO: {
              documents: [
                {
                  aadharCardNumber: formData.aadharCardNumber,
                  aadharImages: aadharPhotosResponseBody,
                  drivingLicenseNumber: formData.drivingLicenseNumber,
                  licenseImages: licenseImageResponseBody
                }
              ]
            }
          };
          saveDriverDetails(driverDTO);
        } else {
          setLoading(false);
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(`${error.response.data.responseMessage}`);
      });
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    clearErrors(name);
    setValue(name, value);
  };

  const saveDriverDetails = data => {
    hubManagerService
      .addDriver(data)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(`${response.data.responseMessage}`);
          window.location.reload();
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('saveDriverDetails', error.response);
      });
  };

  return (
    <div className="py-3">
      <Header breadcrumb="Manage Drivers/Add Drivers" title="Add Drivers" />

      <Card className="custom-card">
        <h5 className="custom-event-card p-3 mb-0">Basic details</h5>

        <div className="add-driver-form">
          <h6 className="text pb-1 pt-3 px-3">Upload Driver Photo</h6>
          <Form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="profileImage px-3">
              <DropzoneArea
                name="profileImage"
                files={profileFiles}
                setFiles={setProfileFiles}
                maxFiles={1}
                accept={{
                  'image/*': ['.jpeg', '.png', '.jpg']
                }}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                disabled={false}
              />
              <UploadedImages
                files={profileFiles}
                setFiles={setProfileFiles}
                setValue={setValue}
                valueKey="profileImage"
                disabled={false}
              />
            </div>
            <div className="item-pair pt-2 px-3">
              <div className="row">
                <div className="col">
                  <p className="input-field pb-4">First Name</p>
                </div>
                <div className="col">
                  <p className="input-field pb-4">Last Name</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    className="rectangle"
                    name="firstName"
                    value={formData.firstName}
                    placeholder="First Name"
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <div className="error">{errors.firstName?.message}</div>
                  )}
                </div>
                <div className="col">
                  <input
                    className="rectangle"
                    name="lastName"
                    value={formData.lastName}
                    placeholder="Last Name"
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <div className="error">{errors.lastName?.message}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="input-field pt-2 pb-4">Mobile Number</p>
                </div>
                <div className="col">
                  <p className="input-field pt-2 pb-4">Email Id</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    className="rectangle"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    placeholder="+91"
                    onChange={handleChange}
                  />
                  {errors.mobileNumber && (
                    <div className="error">{errors.mobileNumber?.message}</div>
                  )}
                </div>
                <div className="col">
                  <input
                    className="rectangle"
                    name="email"
                    value={formData.email}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="error">{errors.email?.message}</div>
                  )}
                </div>
              </div>
              <div className="drop-down">
                <p className="input-field pt-2 pb-4">Gender</p>
                <select
                  className="rectangle"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  {errors.gender && (
                    <div className="error">{errors.gender?.message}</div>
                  )}
                  <option>Select</option>
                  <option>MALE</option>
                  <option>FEMALE</option>
                  <option>OTHERS</option>
                </select>
              </div>
            </div>

            <div className="upload-documents mt-5">
              <h5 className="custom-event-card p-3">Upload Documents</h5>
              <div className="pt-2 px-3">
                <div className="item-pair pt-2">
                  <div className="row">
                    <div className="col">
                      <p className="title">Aadhar Card</p>
                      <div className="col">
                        <p className="input-field pt-3 pb-4">
                          Enter Aadhar Card Number
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        className="rectangle"
                        name="aadharCardNumber"
                        value={formData.aadharCardNumber}
                        placeholder="Enter here"
                        onChange={handleChange}
                      />
                      {errors.aadharCardNumber && (
                        <div className="error">
                          {errors.aadharCardNumber?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="Aadhar-documents pt-3">
                  <h6 className="text pb-2">Upload Aadhar Card Photos</h6>
                  <DropzoneArea
                    name="aadharPhotos"
                    files={aadharFiles}
                    setFiles={setAadharFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                  <UploadedImages
                    files={aadharFiles}
                    setFiles={setAadharFiles}
                    setValue={setValue}
                    valueKey="aadharPhotos"
                  />
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <p className="title">Driving License</p>
                    <div className="col">
                      <p className="input-field pt-3 pb-4">
                        Enter Driving License Number
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      className="rectangle"
                      name="drivingLicenseNumber"
                      value={formData.drivingLicenseNumber}
                      placeholder="Enter here"
                      onChange={handleChange}
                    />
                    {errors.drivingLicenseNumber && (
                      <div className="error">
                        {errors.drivingLicenseNumber?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="License-photos pt-3">
                  <h6 className="text pb-2">Upload Driving License Photos</h6>
                  <DropzoneArea
                    name="licenseImage"
                    files={licenseFiles}
                    setFiles={setLicenseFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />

                  <UploadedImages
                    files={licenseFiles}
                    setFiles={setLicenseFiles}
                    setValue={setValue}
                    valueKey="licenseImage"
                  />
                </div>
                {loading ? (
                  <div className="mt-5">
                    <Loader loading={true} />
                  </div>
                ) : (
                  <div className="pt-5 pb-3 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary savebutton rounded-pill"
                      disabled={
                        !formData.profileImage ||
                        !formData.firstName ||
                        !formData.lastName ||
                        !formData.mobileNumber ||
                        !formData.email ||
                        !formData.gender ||
                        !formData.aadharCardNumber ||
                        !formData.aadharPhotos ||
                        !formData.drivingLicenseNumber ||
                        !formData.licenseImage
                      }
                    >
                      Save Details
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddDriverComponent;
