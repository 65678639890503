import { useLocation } from 'react-router-dom';
import React from 'react';
import HubManagerService from 'core/service/HubManagerService';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Loader from 'core/loader/loader';
import UploadedImages from 'components/common/UploadedImagesComponent';
import DropzoneArea from 'components/common/DropzoneComponent';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';
import DataTransferService from 'core/service/dataTransferService';

const EditDriver = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const hubManagerService = new HubManagerService();
  const [profileFiles, setProfileFiles] = React.useState([]);
  const [aadharFiles, setAadharFiles] = React.useState([]);
  const [licenseFiles, setLicenseFiles] = React.useState([]);
  const [initialFormData, setInitialFormData] = useState({
    profileImage: [],
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    gender: '',
    aadharCardNumber: '',
    aadharPhotos: [],
    drivingLicenseNumber: '',
    licenseImage: []
  });
  const [formData, setFormData] = useState(initialFormData);
  const queryParams = new URLSearchParams(location.search);
  const dataTransferService = new DataTransferService();
  const [isModified, setIsModified] = useState(false);
  const schema = yup
    .object({
      profileImage: yup
        .array()
        .max(1, 'You can only upload only one driverPhoto.'),
      aadharPhotos: yup
        .array()
        .max(2, 'You can only upload up to 2 AadharDocuments.'),
      licenseImage: yup
        .array()
        .max(2, 'You can only upload up to 2 licensePhotos.'),
      firstName: yup.string(),
      lastName: yup.string(),
      mobileNumber: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 digits'),
      email: yup
        .string()
        .transform(value => value.replace(/\s+/g, ''))
        .email('Invalid email format')
        .required('Email is required'),
      gender: yup.string(),
      aadharCardNumber: yup.string(),
      drivingLicenseNumber: yup.string()
    })
    .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(schema)
  });
  const normalizeString = str => str.replace(/\s+/g, '');

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = normalizeString(value);
    const newFormData = {
      ...formData,
      [name]: newValue
    };
    setFormData(newFormData);

    const initialNormalizedValue = normalizeString(initialFormData[name]);
    const isModified = newValue !== initialNormalizedValue;

    setIsModified(isModified);
    clearErrors(name);
    setValue(name, newValue);
  };

  useEffect(() => {
    fetchDriverDetails();
  }, []);

  const fetchDriverDetails = () => {
    hubManagerService
      .fetchDriverDetails(queryParams.get('duid'))
      .then(response => {
        if (response.data.responseCode === 200) {
          const profileImageURL =
            dataTransferService.getBloburl() +
            response.data.responseBody.driverDetails.profileImage;

          const aadharImagesURLs = response.data.responseBody.aadhaarImages.map(
            image => dataTransferService.getBloburl() + image
          );

          const licenseImagesURLs =
            response.data.responseBody.licenseImages.map(
              image => dataTransferService.getBloburl() + image
            );

          const driverData = {
            firstName: response.data.responseBody.driverDetails.firstName || '',
            lastName: response.data.responseBody.driverDetails.lastName || '',
            mobileNumber:
              response.data.responseBody.driverDetails.mobileNumber || '',
            email: response.data.responseBody.driverDetails.email || '',
            gender: response.data.responseBody.driverDetails.gender || '',
            aadharCardNumber:
              response.data.responseBody.aadhaarCardNumber || '',
            drivingLicenseNumber:
              response.data.responseBody.drivingLicenseNumber || '',
            profileImage: profileImageURL
          };

          setInitialFormData(driverData);
          setFormData(driverData);

          setProfileFiles([
            {
              preview: profileImageURL,
              path: 'profileImage'
            }
          ]);

          setAadharFiles(
            aadharImagesURLs.map((url, index) => ({
              preview: url,
              path: `aadharImage-${index}`
            }))
          );

          setLicenseFiles(
            licenseImagesURLs.map((url, index) => ({
              preview: url,
              path: `licenseImage-${index}`
            }))
          );

          setValue('firstName', driverData.firstName);
          setValue('lastName', driverData.lastName);
          setValue('mobileNumber', driverData.mobileNumber);
          setValue('email', driverData.email);
          setValue('gender', driverData.gender);
          setValue('aadharCardNumber', driverData.aadharCardNumber);
          setValue('drivingLicenseNumber', driverData.drivingLicenseNumber);
          setValue('profileImage', profileImageURL ? [profileImageURL] : []);
          setValue('aadharPhotos', aadharImagesURLs);
          setValue('licenseImage', licenseImagesURLs);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('fetchDriverDetails', error.response);
      });
  };

  const editDriverDetails = data => {
    const params = {
      duid: queryParams.get('duid')
    };
    hubManagerService
      .editDriver(params, data)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(`${response.data.responseMessage}`);
          window.location.reload();
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('editDriverDetails', error.response);
      });
  };

  const onSubmit = async data => {
    setLoading(true);
    try {
      const normalizedData = {
        ...data,
        mobileNumber: normalizeString(data.mobileNumber),
        email: normalizeString(data.email)
      };

      schema.validate(normalizedData, { abortEarly: false });

      const driverDTO = {
        driverAccountDTO: {
          mobileNumber: normalizedData.mobileNumber
        },
        driverDetailsDTO: {
          email: normalizedData.email
        }
      };

      editDriverDetails(driverDTO);
    } catch (error) {
      setLoading(false);
      handleErrorResponse('onSubmit', error);
    }
  };

  return (
    <div className="py-3">
      <Header breadcrumb="Manage Drivers/Drivers/edit" title="Edit Driver" />

      <Card className="custom-card">
        <h5 className="custom-event-card p-3 mb-0">Basic details</h5>

        <div className="add-driver-form">
          <h6 className="text pb-1 pt-3 px-3">Upload Driver Photo</h6>
          <Form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="profileImage px-3">
              <DropzoneArea
                name="profileImage"
                files={profileFiles}
                setFiles={setProfileFiles}
                maxFiles={1}
                accept={{
                  'image/*': ['.jpeg', '.png', '.jpg']
                }}
                errors={errors}
                setValue={setValue}
                clearErrors={clearErrors}
                disabled={true}
              />
              <UploadedImages
                files={profileFiles}
                setFiles={setProfileFiles}
                setValue={setValue}
                valueKey="profileImage"
                disabled={true}
              />
            </div>
            <div className="item-pair pt-2 px-3">
              <div className="row">
                <div className="col">
                  <p className="input-field pb-4">First Name</p>
                </div>
                <div className="col">
                  <p className="input-field pb-4">Last Name</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    className="rectangle"
                    name="firstName"
                    value={formData.firstName}
                    placeholder="First Name"
                    disabled
                  />
                  {errors.firstName && (
                    <div className="error">{errors.firstName?.message}</div>
                  )}
                </div>
                <div className="col">
                  <input
                    className="rectangle"
                    name="lastName"
                    value={formData.lastName}
                    placeholder="Last Name"
                    disabled
                  />
                  {errors.lastName && (
                    <div className="error">{errors.lastName?.message}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="input-field pt-2 pb-4">Mobile Number</p>
                </div>
                <div className="col">
                  <p className="input-field pt-2 pb-4">Email Id</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    className="rectangle"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    placeholder="+91"
                    onChange={handleChange}
                  />
                  {errors.mobileNumber && (
                    <div className="error">{errors.mobileNumber?.message}</div>
                  )}
                </div>
                <div className="col">
                  <input
                    className="rectangle"
                    name="email"
                    value={formData.email}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="error">{errors.email?.message}</div>
                  )}
                </div>
              </div>
              <div className="drop-down">
                <p className="input-field pt-2 pb-4">Gender</p>
                <select
                  className="rectangle"
                  name="gender"
                  value={formData.gender}
                  disabled
                >
                  {errors.gender && (
                    <div className="error">{errors.gender?.message}</div>
                  )}
                  <option>Select</option>
                  <option>MALE</option>
                  <option>FEMALE</option>
                  <option>OTHERS</option>
                </select>
              </div>
            </div>

            <div className="upload-documents mt-5">
              <h5 className="custom-event-card p-3">Upload Documents</h5>
              <div className="pt-2 px-3">
                <div className="item-pair pt-2">
                  <div className="row">
                    <div className="col">
                      <p className="title">Aadhar Card</p>
                      <div className="col">
                        <p className="input-field pt-3 pb-4">
                          Enter Aadhar Card Number
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        className="rectangle"
                        name="aadharCardNumber"
                        value={formData.aadharCardNumber}
                        placeholder="Enter here"
                        disabled
                      />
                      {errors.aadharCardNumber && (
                        <div className="error">
                          {errors.aadharCardNumber?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="Aadhar-documents pt-3">
                  <h6 className="text pb-2">Upload Aadhar Card Photos</h6>
                  <DropzoneArea
                    name="aadharPhotos"
                    files={aadharFiles}
                    setFiles={setAadharFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    disabled={true}
                  />
                  <UploadedImages
                    files={aadharFiles}
                    setFiles={setAadharFiles}
                    setValue={setValue}
                    valueKey="aadharPhotos"
                    disabled={true}
                  />
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <p className="title">Driving License</p>
                    <div className="col">
                      <p className="input-field pt-3 pb-4">
                        Enter Driving License Number
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      className="rectangle"
                      name="drivingLicenseNumber"
                      value={formData.drivingLicenseNumber}
                      placeholder="Enter here"
                      disabled
                    />
                    {errors.drivingLicenseNumber && (
                      <div className="error">
                        {errors.drivingLicenseNumber?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="License-photos pt-3">
                  <h6 className="text pb-2">Upload Driving License Photos</h6>
                  <DropzoneArea
                    name="licenseImage"
                    files={licenseFiles}
                    setFiles={setLicenseFiles}
                    maxFiles={2}
                    accept={{
                      'image/*': ['.jpeg', '.png', '.jpg']
                    }}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    disabled={true}
                  />

                  <UploadedImages
                    files={licenseFiles}
                    setFiles={setLicenseFiles}
                    setValue={setValue}
                    valueKey="licenseImage"
                    disabled={true}
                  />
                </div>
                {loading ? (
                  <div className="mt-5">
                    <Loader loading={true} />
                  </div>
                ) : (
                  <div className="pt-5 pb-3 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary savebutton rounded-pill"
                      disabled={loading || !isModified}
                    >
                      Save Details
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default EditDriver;
