import React, { useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import startIcon from './../../assets/img/Book ride/start-pin-filled.png';
import endIcon from './../../assets/img/Book ride/end-pin-filled.png';
import styles from './InvoiceMapComponent.module.scss';
import { Appconfig } from 'core/config/app.config';

const Marker = ({ icon }) => (
  <div className={`${styles['marker-container']}`}>
    <img src={icon} alt="Marker" className={`${styles['marker-icon']}`} />
  </div>
);

const InvoiceMapComponent = ({
  pathCoordinates,
  startCoords,
  endCoords,
  showPolyline
}) => {
  const mapRef = useRef(null);
  const mapsRef = useRef(null);

  useEffect(() => {
    if (pathCoordinates.length > 0 && mapRef.current && mapsRef.current) {
      fitMapToBounds();
      drawPolyline();
    }
  }, [pathCoordinates]);

  const fitMapToBounds = () => {
    const bounds = new mapsRef.current.LatLngBounds();
    pathCoordinates.forEach(coord => {
      bounds.extend(new mapsRef.current.LatLng(coord.lat, coord.lng));
    });
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  };

  const drawPolyline = () => {
    if (mapRef.current && mapsRef.current && showPolyline) {
      const polyline = new mapsRef.current.Polyline({
        path: pathCoordinates,
        geodesic: true,
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 4
      });
      polyline.setMap(mapRef.current);
    }
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '200px',
    paddingBottom: '24px'
  };

  if (!startCoords || !endCoords) {
    return <div>Loading...</div>;
  }

  const center = {
    lat: (startCoords.lat + endCoords.lat) / 2,
    lng: (startCoords.lng + endCoords.lng) / 2
  };

  return (
    <div style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Appconfig.getGoogleMapUrl() }}
        defaultCenter={center}
        defaultZoom={20}
        options={{
          keyboardShortcuts: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          disableDefaultUI: false
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          if (pathCoordinates.length > 0) {
            fitMapToBounds();
            drawPolyline();
          }
        }}
      >
        <Marker lat={startCoords.lat} lng={startCoords.lng} icon={startIcon} />
        <Marker lat={endCoords.lat} lng={endCoords.lng} icon={endIcon} />
      </GoogleMapReact>
    </div>
  );
};

export default InvoiceMapComponent;
