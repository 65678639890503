import DriverDetails from './driverDetails';
import RideHistory from './rideHistory';
import Tabs from 'react-bootstrap/Tabs';
import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useLocation } from 'react-router-dom';
import Header from 'components/common/Header';

function Test() {
  const [activeTab, setActiveTab] = useState('rideHistory');
  const [pathType, setPathType] = useState('');
  const location = useLocation();
  location.pathname;

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.match('drivers') && !pathname.match('active-drivers')) {
      setPathType('Drivers');
    } else {
      setPathType('Active Drivers');
    }
  }, [location]);
  const handleTabChange = tabKey => {
    setActiveTab(tabKey);
  };

  const getTitle = () => {
    switch (activeTab) {
      case 'rideHistory':
        return 'Ride History';
      case 'driverDetails':
        return 'Driver Details';
      default:
        return '';
    }
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb={`Manage Drivers/${pathType}/${getTitle()}`}
        title="Drivers"
      />

      <div className="tab-content">
        <Tabs defaultActiveKey="rideHistory" onSelect={handleTabChange}>
          <Tab eventKey="rideHistory" title="Ride History">
            <RideHistory />
          </Tab>
          <Tab eventKey="driverDetails" title="Driver Details">
            <DriverDetails />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Test;
