import { Appconfig } from 'core/config/app.config';
import axiosInstance from 'core/tokenInterceptor/AxiosInstance';
import { format } from 'date-fns';

export class BlobStorageService {
  MAX_UPLOAD_SIZE_IN_BYTES = 1048576;
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'file';
  }

  formatDate(dateTimeString) {
    if (!dateTimeString) return '--';
    const date = new Date(dateTimeString);
    return format(date, 'dd-MM-yyyy, hh:mm:ss a');
  }

  uploadImage(files) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
    return axiosInstance.post(this.Api + '/upload-images', formData, {
      observe: 'response',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}
export default BlobStorageService;
