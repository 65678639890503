import React from 'react';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

const DropzoneArea = ({
  name,
  files,
  setFiles,
  maxFiles,
  accept,
  errors,
  setValue,
  clearErrors,
  disabled
}) => {
  const onDrop = acceptedFiles => {
    if (disabled) return;

    if (acceptedFiles.length + files.length > maxFiles) {
      toast.error(`You can only upload up to ${maxFiles} files.`);
      return;
    }
    if (errors[name]) {
      clearErrors(name);
    }
    setValue(name, [...files, ...acceptedFiles]);
    setFiles(prevFiles => [
      ...prevFiles,
      ...acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    ]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    maxFiles,
    disabled
  });

  return (
    <div
      {...getRootProps({
        className: classNames('dropzone-area py-6', {
          'is-invalid': errors[name],
          'dropzone-disabled': disabled
        })
      })}
    >
      <input name={name} {...getInputProps()} />
      <Flex justifyContent="center">
        <img src={cloudUpload} alt="" width={25} className="me-2" />
        <p className="fs-9 text-700">Drop your images here</p>
      </Flex>
      <div className="invalid-feedback">{errors[name]?.message}</div>
    </div>
  );
};

export default DropzoneArea;
