import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import DataTransferService from './dataTransferService';
import { Appconfig } from 'core/config/app.config';

const SOCKET_URL = Appconfig.getAPIURI() + 'rush-socket';
const dataTransferService = new DataTransferService();

class WebSocketService {
  constructor({ onNotificationReceived }) {
    this.onNotificationReceived = onNotificationReceived;
    this.stompClient = null;
    this.isConnected = false;
  }

  connect() {
    const socket = new SockJS(SOCKET_URL);
    this.stompClient = Stomp.over(socket);
    this.stompClient.debug = null;
    this.stompClient.connect(
      {},
      () => {
        this.isConnected = true;
        this.stompClient.subscribe('/notifications', message => {
          if (
            message.body &&
            dataTransferService.getHubUid() === message.body
          ) {
            this.onNotificationReceived(true);
          }
        });
      },
      error => {
        console.error('WebSocketService connection failed:', error);
      }
    );
  }

  disconnect() {
    if (this.stompClient && this.isConnected) {
      this.stompClient.disconnect(() => {
        this.isConnected = false;
      });
    }
  }
}

export default WebSocketService;
