import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import HubManagerService from 'core/service/HubManagerService';
import SubtleBadge from 'components/common/SubtleBadge';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import { useNavigate } from 'react-router-dom';
import driverNotFound from 'assets/img/drivers-not-found.png';
import {
  PaginationComponent,
  toPascalWord
} from 'components/commonComponent/commonComponents';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';

const DriversComponent = () => {
  const hubManagerService = new HubManagerService();
  const [drivers, setDrivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const ENTRIES_PER_PAGE = 10;

  const columnNames = [
    'Driver Id',
    'First Name',
    'Last Name',
    'Phone Number',
    'Email Id',
    'Status',
    'Action'
  ];

  const renderTableHeaders = () => {
    return (
      <tr>
        {columnNames.map((columnName, index) => (
          <th key={index} className="col-fixed-width">
            {columnName}
          </th>
        ))}
      </tr>
    );
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      fetchDrivers();
    } else {
      searchDrivers();
    }
  }, [currentPage, searchTerm]);

  const handleEditClick = (event, data) => {
    event.preventDefault();
    navigate(
      `/manage-drivers/drivers/ride-history?duid=${encodeURIComponent(
        data.duid
      )}`
    );
  };

  const handleEditDriverClick = (event, data) => {
    event.preventDefault();
    navigate(
      `/manage-drivers/drivers/edit?duid=${encodeURIComponent(data.duid)}`
    );
  };

  const TableRow = ({ data }) => {
    const getStatusBackgroundColor = status => {
      switch (status) {
        case 'ONLINE':
          return 'success';
        default:
          return 'warning';
      }
    };

    const bg = getStatusBackgroundColor(data.driverStatusMode);
    return (
      <tr className="align-middle">
        <td className="col-fixed-width">
          <a href="#" onClick={event => handleEditClick(event, data)}>
            {data.duid}
          </a>
        </td>
        <td className="col-fixed-width">{data.firstName}</td>
        <td className="col-fixed-width">{data.lastName}</td>
        <td className="col-fixed-width">{data.mobileNumber}</td>
        <td className="col-fixed-width">{data.email}</td>
        <td>
          <SubtleBadge pill bg={bg}>
            {toPascalWord(data.driverStatusMode)}
          </SubtleBadge>
        </td>
        <td>
          <CardDropdown drop="end">
            <Dropdown.Item
              href="#"
              onClick={event => handleEditDriverClick(event, data)}
            >
              Edit
            </Dropdown.Item>
          </CardDropdown>
        </td>
      </tr>
    );
  };

  const fetchDrivers = () => {
    setLoading(true);
    hubManagerService
      .fetchDrivers(currentPage, ENTRIES_PER_PAGE)
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          setDrivers(responseBody.driverDetailsResponseDTOList || []);
          setTotalPages(Math.ceil(responseBody.totalItems / ENTRIES_PER_PAGE));
        }
      })
      .catch(error => {
        setLoading(false);
        setDrivers([]);
        handleErrorResponse('fetchDrivers', error.response);
      });
  };

  const searchDrivers = () => {
    setLoading(true);
    hubManagerService
      .searchDrivers(searchTerm, currentPage, ENTRIES_PER_PAGE)
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          setDrivers(responseBody.searchResult || []);
          setTotalPages(Math.ceil(responseBody.totalItems / ENTRIES_PER_PAGE));
        }
      })
      .catch(error => {
        setLoading(false);
        setDrivers([]);
        handleErrorResponse('searchDrivers', error.response);
      });
  };

  const renderCustomers = () => {
    if (!Array.isArray(drivers) || drivers.length === 0) {
      return null;
    }
    return drivers.map((entry, index) => <TableRow data={entry} key={index} />);
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const isNextDisabled = currentPage >= totalPages - 1;
  const isPrevDisabled = currentPage === 0;

  return (
    <div className="py-3">
      <Header breadcrumb="Manage Drivers/Drivers" title="Drivers" />

      <Card>
        <div className=" p-3">
          <Flex className="justify-content-between">
            <div className="search-container justify-content-start pointer">
              <FontAwesomeIcon icon="search" className="search-icon pointer" />
              <input
                className="search-content pointer"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </Flex>
        </div>
      </Card>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {drivers.length === 0 ? (
            <div className="text-center no-content-text">
              <img
                className="w-50 h-25"
                src={driverNotFound}
                alt="driversNotFound"
              />
              <p>No Drivers Found</p>
            </div>
          ) : (
            <Card>
              <Card.Body className="p-0">
                <Table striped responsive hover>
                  <thead className="header-padding">
                    {renderTableHeaders()}
                  </thead>
                  <tbody>{renderCustomers()}</tbody>
                </Table>
                <PaginationComponent
                  prevPage={prevPage}
                  nextPage={nextPage}
                  isNextDisabled={isNextDisabled}
                  isPrevDisabled={isPrevDisabled}
                />
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default DriversComponent;
