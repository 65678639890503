import ExampleCustomInput from '../../../../../core/customCalender/customCalender';
import React, { useState, useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import HubManagerService from 'core/service/HubManagerService';
import SubtleBadge, { statusByColor } from 'components/common/SubtleBadge';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../../../assets/scss/user.scss';
import DatePicker from 'react-datepicker';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import NoRideHistoryImage from 'assets/img/no-ride-history.png';
import {
  DropdownComponent,
  dateStartsWithDay,
  PaginationComponent,
  toPascalWord
} from 'components/commonComponent/commonComponents';
import { SearchAPIComponent } from 'components/common/SearchAPIComponent';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';

const RideHistoryComponent = () => {
  const ENTRIES_PER_PAGE = 10;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const hubManagerService = new HubManagerService();
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedPayment, setSelectedPayment] = useState('All');
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const setRideHistory = useState(false)[1];
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({
    duid: ''
  });
  const columnNames = [
    'Ride ID',
    'Pickup Location',
    'Drop Location',
    'Ride Status',
    'Amount',
    'Payment Method',
    'Invoice'
  ];

  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : new Date();
  });

  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate.toISOString());
  }, [selectedDate]);

  useEffect(() => {
    setFormData({
      duid: queryParams.get('duid') || ''
    });
  }, [location.search]);

  useEffect(() => {
    if (formData.duid && !searchTerm) {
      fetchDriverRideHistory();
    }
  }, [
    formData.duid,
    currentPage,
    selectedDate,
    selectedStatus,
    selectedPayment,
    searchTerm
  ]);

  const renderTableHeaders = () => {
    return (
      <tr>
        {columnNames.map((columnName, index) => (
          <th key={index} className="col-fixed-width">
            {columnName}
          </th>
        ))}
      </tr>
    );
  };

  const TableRow = ({ data }) => {
    const handleEditClick = (event, ride) => {
      event.preventDefault();
      const routePrefix = location.pathname.includes('active-drivers')
        ? '/manage-drivers/active-drivers/ride-history/invoice'
        : '/manage-drivers/drivers/ride-history/invoice';

      navigate(`${routePrefix}?ruid=${encodeURIComponent(ride.ruid)}`, {
        state: ride
      });
    };

    return (
      <tr className="align-middle">
        <td className="col-fixed-width">{data.ruid}</td>
        <td className="col-fixed-width">
          {data.pickupAddressText || data.pickupDetails?.pickUpAddress?.address}
        </td>
        <td className="col-fixed-width">
          {data.dropAddressText || data.dropDetails?.dropAddress?.address}
        </td>
        <td>
          <SubtleBadge pill bg={statusByColor(data.rideStatus)}>
            {toPascalWord(data.rideStatus)}
          </SubtleBadge>
        </td>
        <td className="col-fixed-width">
          {data.rideStatus === 'CANCELLED'
            ? '₹ 0'
            : `₹ ${data.fareAfterDiscount || data.fare}`}
        </td>
        <td className="col-fixed-width">{data.paymentMethod}</td>
        <td>
          {data.rideStatus === 'CANCELLED' ? (
            <span className="disable-view">View</span>
          ) : data.rideStatus === 'COMPLETED' && data.paymentReceived ? (
            <a href="#" onClick={event => handleEditClick(event, data)}>
              View
            </a>
          ) : (
            <span className="blue-text">_______</span>
          )}
        </td>
      </tr>
    );
  };

  const fetchDriverRideHistory = () => {
    const rideStatus = selectedStatus === 'All' ? 'all' : selectedStatus;
    const paymentMethod = selectedPayment === 'All' ? 'all' : selectedPayment;

    setLoading(true);
    hubManagerService
      .fetchDriverRideHistory(
        formData.duid,
        currentPage,
        ENTRIES_PER_PAGE,
        rideStatus,
        paymentMethod,
        dateStartsWithDay(selectedDate)
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setRideHistory(response.data.responseBody.rideDetailsDTOList);
          setData(response.data.responseBody);
          setTotalPages(
            Math.ceil(response.data.responseBody.totalItems / ENTRIES_PER_PAGE)
          );
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('fetchDriverRideHistory', error.response);
        setData(null);
      });
  };

  const handleSearchResult = result => {
    if (result !== null) {
      const { searchResult, totalItems } = result.responseBody;
      const updatedResult = {
        rideDetailsDTOList: searchResult,
        totalItems: totalItems
      };
      setRideHistory(updatedResult.rideDetailsDTOList);
      setData(updatedResult);
      setTotalPages(Math.ceil(updatedResult.totalItems / ENTRIES_PER_PAGE));
    } else {
      setData(null);
    }
  };

  const renderCustomers = () => {
    if (!data || !data.rideDetailsDTOList) return null;
    return data.rideDetailsDTOList.map((entry, index) => (
      <TableRow data={entry} key={index} />
    ));
  };

  const handleRideStatus = selectedStatus => {
    setSelectedStatus(selectedStatus === 'all' ? '' : selectedStatus);
  };

  const handlePaymentMethod = selectedPayment => {
    setSelectedPayment(selectedPayment === 'all' ? '' : selectedPayment);
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const isNextDisabled = currentPage >= totalPages - 1;
  const isPrevDisabled = currentPage === 0;

  const isEmpty =
    !data || !data.rideDetailsDTOList || data.rideDetailsDTOList.length === 0;

  const rideStatusOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'Completed', label: 'Completed' },
    { id: 3, value: 'Cancelled', label: 'Cancelled' }
  ];
  const paymentOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'CASH', label: 'CASH' },
    { id: 3, value: 'UPI', label: 'UPI' }
  ];

  return (
    <div>
      <Card>
        <div className="p-3">
          <Flex className="justify-content-between">
            <SearchAPIComponent
              searchTerm={searchTerm}
              onSearchChange={setSearchTerm}
              status={selectedStatus}
              onSearchResult={handleSearchResult}
              pageIndex={currentPage}
              pageSize={ENTRIES_PER_PAGE}
              service={'searchDriverRideHistory'}
              loading={setLoading}
              date={dateStartsWithDay(selectedDate)}
              duid={formData.duid}
              paymentMethod={selectedPayment}
            />
            <Flex className="justify-content-end">
              <div className="me-3">
                <DropdownComponent
                  title="Ride Status"
                  selected={selectedStatus}
                  options={rideStatusOptions}
                  onSelect={handleRideStatus}
                />
              </div>
              <DropdownComponent
                title="Payment Method"
                selected={selectedPayment}
                options={paymentOptions}
                onSelect={handlePaymentMethod}
              />
            </Flex>
          </Flex>
          <Flex className="d-flex justify-content-end mt-3">
            <div>
              <DatePicker
                popperPlacement="bottom-start"
                selected={selectedDate}
                onChange={date => setSelectedDate(date)}
                customInput={
                  <ExampleCustomInput
                    value={selectedDate}
                    onChange={event => setSelectedDate(event.target.value)}
                  />
                }
                maxDate={new Date()}
                dateFormat="dd-MM-yyyy"
                locale="en"
              />
            </div>
          </Flex>
        </div>
      </Card>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {isEmpty ? (
            <div className="text-center no-drivers-text">
              <img
                className="w-50 h-25"
                src={NoRideHistoryImage}
                alt="ride history not found"
              />
              <p>Ride history not found</p>
            </div>
          ) : (
            <Card>
              <Card.Body className="p-0">
                <Table striped responsive hover>
                  <thead className="header-padding">
                    {renderTableHeaders()}
                  </thead>
                  <tbody>{renderCustomers()}</tbody>
                </Table>
                <PaginationComponent
                  prevPage={prevPage}
                  nextPage={nextPage}
                  isNextDisabled={isNextDisabled}
                  isPrevDisabled={isPrevDisabled}
                />
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default RideHistoryComponent;
