import React from 'react';
import './ImagePreviewComponent.scss';
import Close from 'assets/img/icons/Close.png';
import ArrowForward from 'assets/img/icons/Arrow-forward.png';
import ArrowBack from 'assets/img/icons/Arrow-back.png';

const ImagePreviewComponent = ({
  show,
  images,
  currentIndex,
  handleClose,
  handlePrev,
  handleNext
}) => {
  return (
    <div className={`image-preview-overlay ${show ? 'show' : ''}`}>
      <button className="close-button" onClick={handleClose}>
        <img src={Close} alt="Close" />
      </button>
      {images.length > 1 && (
        <>
          <button className="nav-button left" onClick={handlePrev}>
            <img src={ArrowBack} alt="Previous" />
          </button>
          <button className="nav-button right" onClick={handleNext}>
            <img src={ArrowForward} alt="Next" />
          </button>
        </>
      )}
      <div>
        <img
          src={images[currentIndex]}
          alt="Preview"
          className="img-fluid img-responsive"
        />
      </div>
    </div>
  );
};

export default ImagePreviewComponent;
