import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Invoice from 'components/side-nav/manageDrivers/driverInfo/invoice';
import Starter from 'components/pages/Starter';
import AddDriver from 'components/side-nav/manageDrivers/addDriver';
import ActiveDrivers from 'components/side-nav/manageDrivers/activeDrivers';
import Driver from 'components/side-nav/manageDrivers/driverInfo';
import AddVehicle from 'components/side-nav/manageVehicles/addVehicle';
import ChangeVehicle from 'components/side-nav/manageVehicles/changeVehicle';
import EditVehicle from 'components/side-nav/manageVehicles/editVehicle';
import HubVehicles from 'components/side-nav/manageVehicles/hubVehicles';
import ManageRides from 'components/side-nav/manageRides';
import Dashboard from 'components/side-nav/dashboard';
import Maps from 'components/side-nav/maps';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';

import AuthGuard from 'core/guard/authguard';
import Notification from 'components/notification';
import LoginGuard from 'core/guard/loginGuard';
import HubDrivers from 'components/side-nav/manageDrivers/drivers';
import OnlineVehicles from 'components/side-nav/manageVehicles/onlineVehicles';
import EditDriver from 'components/side-nav/manageDrivers/editDriver';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/*- ---------------------Card ---------------------------  */}
      <Route path="/login" element={<LoginGuard element={CardLogin} />} />
      <Route path="/logout" element={<Logout />} />

      <Route element={<MainLayout />}>
        {/*----------------------Dashboard-------------------*/}
        <Route path="/" element={<AuthGuard element={Dashboard} />} />

        {/*------------------Manage Drivers------------------*/}
        <Route
          path="manage-drivers/addDriver"
          element={<AuthGuard element={AddDriver} />}
        />
        <Route
          path="manage-drivers/drivers"
          element={<AuthGuard element={HubDrivers} />}
        />
        <Route
          path="manage-drivers/drivers/ride-history"
          element={<AuthGuard element={Driver} />}
        />
        <Route
          path="manage-drivers/drivers/driver-details"
          element={<AuthGuard element={Driver} />}
        />
        <Route
          path="manage-drivers/drivers/ride-history/invoice"
          element={<AuthGuard element={Invoice} />}
        />
        <Route
          path="manage-drivers/active-drivers"
          element={<AuthGuard element={ActiveDrivers} />}
        />
        <Route
          path="manage-drivers/drivers-list/driver"
          element={<AuthGuard element={Driver} />}
        />
        <Route
          path="manage-drivers/active-drivers/ride-history"
          element={<AuthGuard element={Driver} />}
        />
        <Route
          path="manage-drivers/active-drivers/driver-details"
          element={<AuthGuard element={Driver} />}
        />
        <Route
          path="manage-drivers/active-drivers/ride-history/invoice"
          element={<AuthGuard element={Invoice} />}
        />
        <Route
          path="manage-drivers/drivers/edit"
          element={<AuthGuard element={EditDriver} />}
        />
        {/*--------------------Manage Vehicles-------------*/}
        <Route
          path="manage-vehicles/addVehicle"
          element={<AuthGuard element={AddVehicle} />}
        />
        <Route
          path="manage-vehicles/changeVehicle"
          element={<AuthGuard element={ChangeVehicle} />}
        />
        <Route
          path="manage-vehicles/hub-vehicles"
          element={<AuthGuard element={HubVehicles} />}
        />
        <Route
          path="manage-vehicles/online-vehicles"
          element={<AuthGuard element={OnlineVehicles} />}
        />
        <Route
          path="manage-vehicles/hub-vehicles/edit"
          element={<AuthGuard element={EditVehicle} />}
        />
        <Route
          path="manage-rides"
          element={<AuthGuard element={ManageRides} />}
        />
        <Route
          path="manage-rides/invoice"
          element={<AuthGuard element={Invoice} />}
        />
        <Route path="maps" element={<AuthGuard element={Maps} />} />

        {/*------------------Profile------------------*/}
        <Route path="pages/starter" element={<Starter />} />
        <Route path="user/profile" element={<Profile />} />
        <Route path="user/settings" element={<Settings />} />
        <Route
          path="notification"
          element={<AuthGuard element={Notification} />}
        />
      </Route>

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
